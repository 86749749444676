import React from 'react'
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { MdOutlineFilterCenterFocus } from "react-icons/md";
import { TbBrandAirtable } from "react-icons/tb";
import { LuFilePieChart } from "react-icons/lu";
import { LuUsers2 } from "react-icons/lu";
import { FaMagic } from "react-icons/fa";
import { PiWallFill } from "react-icons/pi";
import { PiSmileyStickerFill } from "react-icons/pi";
import { PiGlobeStandFill } from "react-icons/pi";
import { RiImageCircleFill } from "react-icons/ri";
import Translator from '../../components/I18n/Translator';

function Informationsection() {
    return (
        <div className='information-section'>
            <div id="exportech-link" class="page-section bg-black clearfix">
                <div class="fes7-img-cont col-md-5">
                    <div class="fes7-img">
                        <img src="https://store.exportech.com.pt/wp-content/uploads/2024/03/IMG_6174-min.jpg" alt="" />
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-md-offset-6 fes7-text-cont p-80-cont">
                            <h1 className='title'> Exportech Portugal<br /> <small>Your Security Partner</small> </h1>
                            <p class="mb-60"><Translator path={"home.home_text_area3.description"} /></p>

                            <div class="row">

                                <div class="col-md-6 col-sm-6">
                                    <div class="fes7-box wow fadeIn" >
                                        <div class="fes7-box-icon">
                                            <MdOutlineFilterCenterFocus />
                                        </div>
                                        <h3><Translator path={"home.home_text_area3.text1"} /></h3>
                                        <p><Translator path={"home.home_text_area3.text2"} /></p>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-6">
                                    <div class="fes7-box wow fadeIn" data-wow-delay="200ms">
                                        <div class="fes7-box-icon">
                                            <TbBrandAirtable />
                                        </div>
                                        <h3><Translator path={"home.home_text_area3.text3"} /></h3>
                                        <p><Translator path={"home.home_text_area3.text4"} /></p>
                                    </div>
                                </div> 
                            </div>

                            <div class="row"> 
                                <div class="col-md-6 col-sm-6">
                                    <div class="fes7-box wow fadeIn" data-wow-delay="400ms">
                                        <div class="fes7-box-icon">
                                            <LuFilePieChart />
                                        </div>
                                        <h3><Translator path={"home.home_text_area3.text5"} /></h3>
                                        <p><Translator path={"home.home_text_area3.text6"} /></p>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-6">
                                    <div class="fes7-box wow fadeIn" data-wow-delay="400ms">
                                        <div class="fes7-box-icon">
                                            <LuUsers2 />
                                        </div>
                                        <h3><Translator path={"home.home_text_area3.text7"} /></h3>
                                        <p><Translator path={"home.home_text_area3.text8"} /></p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="page-section fes4-cont benefits-section">
                <div class="wrapper">
                    <div className="bns-area">
                        <div className="text">
                            <div className="title-info center">
                                <strong><Translator path={"home.home_text_area4.small"} /></strong>
                                <h1 className="title"><Translator path={"home.home_text_area4.title"} /></h1>
                                <div className="bar"></div>
                            </div>
                            <p><Translator path={"home.home_text_area4.description"} /></p>
                        </div>
                        <div class="rows">
                            <div class="col-xs-12 col-sm-4 col-md-4">
                                <div class="fes4-box wow fadeIn" data-wow-delay="200ms">
                                    <div class="fes4-title-cont" >
                                        <div class="fes4-box-icon">
                                            <FaMagic />
                                        </div>
                                        <h3><span class="bold"><Translator path={"home.home_text_area4.text1"} /></span></h3>
                                        <p><Translator path={"home.home_text_area4.text2"} /></p>
                                    </div>
                                    <div><Translator path={"home.home_text_area4.text3"} /> </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-4 col-md-4">
                                <div class="fes4-box wow fadeIn" data-wow-delay="400ms">
                                    <div class="fes4-title-cont" >
                                        <div class="fes4-box-icon">
                                            <PiWallFill />
                                        </div>
                                        <h3><span class="bold"><Translator path={"home.home_text_area4.text4"} /></span></h3>
                                        <p><Translator path={"home.home_text_area4.text5"} /></p>
                                    </div>
                                    <div><Translator path={"home.home_text_area4.text6"} /> </div>
                                </div>
                            </div>



                            <div class="col-xs-12 col-sm-4 col-md-4">
                                <div class="fes4-box wow fadeIn" data-wow-delay="600ms">
                                    <div class="fes4-title-cont" >
                                        <div class="fes4-box-icon">
                                           <PiSmileyStickerFill />
                                        </div>
                                        <h3><span class="bold"><Translator path={"home.home_text_area4.text7"} /></span></h3>
                                        <p><Translator path={"home.home_text_area4.text8"} /></p>
                                    </div>
                                    <div><Translator path={"home.home_text_area4.text9"} /></div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-4 col-md-4">
                                <div class="fes4-box wow fadeIn" data-wow-delay="800ms" >
                                    <div class="fes4-title-cont" >
                                        <div class="fes4-box-icon">
                                              <PiGlobeStandFill /> 
                                        </div>
                                        <h3><span class="bold"><Translator path={"home.home_text_area4.text10"} /></span></h3>
                                        <p><Translator path={"home.home_text_area4.text11"} /></p>
                                    </div>
                                    <div><Translator path={"home.home_text_area4.text12"} /></div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-4">
                                <div class="fes4-box wow fadeIn" data-wow-delay="1000ms">
                                    <div class="fes4-title-cont" >
                                        <div class="fes4-box-icon">
                                             <RiImageCircleFill />
                                        </div>
                                        <h3><span class="bold"><Translator path={"home.home_text_area4.text13"} /></span></h3>
                                        <p><Translator path={"home.home_text_area4.text14"} /></p>
                                    </div>
                                    <div><Translator path={"home.home_text_area4.text15"} /> </div>
                                </div>
                            </div>


                            <Link to="/about" class="col-xs-12 col-sm-4 col-md-4">
                                <div class="col">
                                    <div class="fes4-box wow learn-more-box fadeIn" data-wow-delay="1000ms">
                                        <div className="learn-more-txt"><Translator path={"home.home_text_area4.text16"} /></div>
                                        <BsArrowRight />
                                    </div>
                                </div>
                            </Link>

                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Informationsection
